import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'

import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const RemoteInspection = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title="Remote Inspections for Construction Projects | Remote Virtual Inspection"
        description="Remote Inspection via 360 panoramic photos allows for comprehensive monitoring and verification of construction projects without physical presence, enabling stakeholders to make informed decisions, track progress, identify delays, ensure transparency in financial dealings, and document changes effectively through the SIGNAX  cloud-based platform."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            April 15, 2024
          </Typography>
          <Typography variant="h1" color="blue" size={40}>
            Remote Inspection (Photo360)
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Verify the completed scope and construction works are under
                remote supervision
              </Typography>
              <Typography variant="body1" size={18}>
                Utilizing 360 panoramic ground photographs allows for the
                detailed verification of completed construction scopes and works
                without needing physical presence on site. The SIGNAX INSPECTION
                service, with its cloud-based storage of panoramas taken
                regularly from the same spot, enhances remote supervision
                capabilities, ensuring that project milestones are met as
                planned.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/remote-inspection/image-1.png"
                alt="Verify the completed scope and construction works are under remote supervision"
                title="Verify the completed scope and construction works are under remote supervision"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              An objective view from the construction site for management
              decisions
            </Typography>
            <Typography variant="body1" size={18}>
              Adopting 360 panoramic images offers management an objective,
              unfiltered view of the construction site's current state. This
              comprehensive perspective, facilitated by SIGNAX INSPECTION
              organized and accessible cloud storage, aids in making informed
              decisions based on real-time, visual data, bridging the gap
              between site realities and management strategies.
            </Typography>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Construction progress remote monitoring and identifying any
                delays
              </Typography>
              <Typography variant="body1" size={18}>
                By regularly capturing the construction site through 360
                panoramic ground photos, stakeholders and other team members can
                monitor progress remotely with precision. The SIGNAX INSPECTION
                platform enables the tracking of developments over time, making
                it easier to identify and address any delays or discrepancies in
                the construction timeline, thus maintaining project schedules
                and budgets.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/remote-inspection/image-2.png"
                alt="Construction progress remote monitoring and identifying any delays"
                title="Construction progress remote monitoring and identifying any delays"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              Visualization provides reassurance in payment agreements, avoiding
              potential deception
            </Typography>
            <Typography variant="body1" size={18}>
              The clarity and detail offered by 360 panoramic photography
              provide a strong foundation for transparent payment agreements.
              With the SIGNAX INSPECTION service, these visual records serve as
              indisputable evidence of work progress, minimizing the risk of
              misunderstandings or deceit in financial transactions and
              fostering trust between parties.
            </Typography>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Changes visualization
              </Typography>
              <Typography variant="body1" size={18}>
                It is invaluable to visualize changes on the construction site
                over time through 360-degree panoramic photographs. SIGNAX
                INSPECTION’s service not only stores these comprehensive visual
                records but also allows for easy comparison of different stages
                of construction highlights modifications, and ensures that all
                alterations align with project goals and regulations.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/remote-inspection/image-3.png"
                alt="Changes visualization"
                title="Changes visualization"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate('/digital-services/')}
          >
            Other digital services
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default RemoteInspection

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
